import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexiblesDesignImage from "../components/FlexiblesDesignImage";
import {Pricing} from "../components/pricing";

const FlexiblesDesign = (props) => (
    <Layout>
        <SEO title="Flexibles Design" keywords={[`medoboard`, `Arztsoftware`, `intelligent`, `einfach`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <FlexiblesDesignImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Flexibles Design</h2>
                    <h4 className="text-muted">Medoboard passt sich fachlichen Anforderung schnell und einfach an</h4>
                    <p className="lead">
                        Die Patientenkartei kann schnell und einfach auf fachliche Spezifika adaptiert werden.
                    </p>
                    <p>
                        Unterschiedliche medizinische Fachbereicht benötigen selbsverständlich unterschiedliche Unterstützung.
                        Und da es technisch aufwändig ist für jeden medizinischen Fachbereich eine eigene Lösung zu realisieren, haben
                        die meisten Softwarehersteller eine allgemeine Lösung entwickelt, die vieles kann, aber nicht fachliche Spezifika umfasst.
                    </p>
                    <p>
                        Medoboard wurde grundsätzlich flexibel entwickelt:
                    </p>
                    <p>
                        Das Datenbankschema von Medoboard kann sehr schnell und einfach an unterschiedliche Anforderungen angepasst werden.
                        Und der Clou ist: Durch die Flexibilität kann Medoboard wirklich 100% auf ihre Wünsche angepasst werden.
                    </p>
                    <ul>
                        <li><b>Spezielle medizinische Fachbegriffe?</b><br />Kein Problem.</li>
                    </ul>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
)

export default FlexiblesDesign;